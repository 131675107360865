export default {
  props: ['contactsFileUploadResponse'],
  name: 'ContactsTable',
  data () {
    return {
    }
  },
  computed: {
  },

  methods: {

  },
  created () {

  },
  mounted () {
  },
}
